type DomainType = 'betone' | 'mirror'

type PublicFileKey =
  | 'publicFileMarketbook'
  | 'publicFileMarketFilterConfig'
  | 'publicFileFastBetConfig'
  | 'publicFilePrivacyPolicy'
  | 'publicFileCookiesPolicy'
  | 'publicFileTerms'
  | 'publicFileAmlPolicy'
  | 'publicFileResponsibleGambling'
  | 'publicFileSelfExclusion'
  | 'publicFileReloadBonusTerms'
  | 'publicFileCashbackTerms'
  | 'publicFileRakeBackTerms'
  | 'publicFileFreebetTerms'
  | 'publicFileFreespinsTerms'
  | 'publicFileReferralProgramTerms'
  | 'publicFileRatRaceTerms'
  | 'publicFileCasinoQuestTerms'
  | 'publicFileCasinoBetTerms'
  | 'publicFileBonusForDepositTerms'
  | 'publicFileBetTerms'
  | 'publicFileCasinoTerms'

interface FeatureConfig {
  isShownLicenceValidator: boolean
  isShownFooterCopyright: boolean
  isShownFooterLicense: boolean
  isShownFooterDocuments: boolean
  isShownCookieDocuments: boolean
  isShownSupportBlockOnHelpPage: boolean
  customDocuments: Partial<Record<PublicFileKey, string>>
}

const config: Record<DomainType, FeatureConfig> = {
  betone: {
    isShownLicenceValidator: true,
    isShownFooterCopyright: true,
    isShownFooterLicense: true,
    isShownFooterDocuments: true,
    isShownCookieDocuments: true,
    isShownSupportBlockOnHelpPage: false,
    customDocuments: {},
  },
  mirror: {
    isShownLicenceValidator: false,
    isShownFooterCopyright: false,
    isShownFooterLicense: false,
    isShownFooterDocuments: false,
    isShownCookieDocuments: false,
    isShownSupportBlockOnHelpPage: false,
    customDocuments: {},
  },
}

export function useDomainFeatures() {
  const url = useRequestURL()

  const domainType = computed<DomainType>(() => {
    if (
      url.hostname.includes('betone.com') ||
      url.hostname.includes('localhost') ||
      url.hostname.includes('.sxl.bet')
    )
      return 'betone'

    return 'mirror'
  })

  const featureConfig = computed(() => config[domainType.value])

  return toRefs(toReactive(featureConfig))
}
